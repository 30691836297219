/** @type {import('tailwindcss').Config} */
const spacings = {
  none: "0",
  "4xs": "2px",
  "3xs": "4px",
  "2xs": "6px",
  xs: "8px",
  sm: "12px",
  md: "16px",
  lg: "20px",
  xl: "24px",
  "2xl": "32px",
  "3xl": "40px",
};

const boxShadows = {
  none: "none",
  xs: "0px 1px 2px 0px rgba(16, 24, 40, 0.05);",
  sm: "0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1);",
  md: "0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.1);",
  lg: "0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);",
  xl: "0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08);",
  "2xl": "0px 24px 48px -12px rgba(16, 24, 40, 0.18);",
  "3xl": "0px 32px 64px -12px rgba(16, 24, 40, 0.14);",
};

module.exports = {
  content: ["./src/**/*.{js,ts,jsx,tsx,mdx}"],
  theme: {
    extend: {
      // TODO: needs to dynamically load theme based on template
      // UPDATE: we can load theme to tailwind the same way as the primary color
      // defaultTheme
      colors: {
        primary: "var(--theme-colors-primary)",
        secondary: "#969696",
        backgroundReverse: "#FCFCFD",
        gray: {
          25: "#FCFCFD",
          50: "#F9FAFB",
          100: "#F2F4F7",
          200: "#EAECF0",
          300: "#D0D5DD",
          400: "#98A2B3",
          500: "#667085",
          600: "#475467",
          700: "#344054",
          800: "#1D2939",
          900: "#101828",
          950: "#0C111D",
        },
        error: {
          25: "#FFFBFA",
          50: "#FEF3F2",
          100: "#FECDCA",
          200: "#FECDCA",
          300: "#FDA29B",
          400: "#F97066",
          500: "#F04438",
          600: "#D92D20",
          700: "#B42318",
          800: "#912018",
          900: "#7A271A",
          950: "#55160C",
        },
        warning: {
          25: "#FFFCF5",
          50: "#FFFAEB",
          100: "#FEE4E2",
          200: "#FEDF89",
          300: "#FEC84B",
          400: "#FDB022",
          500: "#F79009",
          600: "#DC6803",
          700: "#B54708",
          800: "#93370D",
          900: "#7A2E0E",
          950: "#4E1D09",
        },
        success: {
          25: "#F9FAFB",
          50: "#FEF3F2",
          100: "#FEF3F2",
          200: "#ABEFC6",
          300: "#75E0A7",
          400: "#47CD89",
          500: "#17B26A",
          600: "#079455",
          700: "#067647",
          800: "#085D3A",
          900: "#074D31",
          950: "#053321",
        },
        bg: {
          default: "#FFFFFF",
          reverse: "#f5f7f9",
          wash: "#252a31",
          divider: "#F6F7F8",
          border: "#EBECED",
          inactive: "#bac7d5",
          dark: "#E4E4E4",
          gray: "#667085",
        },
        brand: {
          default: "#4f4335",
          alt: "#fe9931",
          wash: "#E8E5FF",
          border: "#DDD9FF",
          dark: "#2A0080",
        },
        space: {
          default: "#0062D6",
          alt: "#1CD2F2",
          wash: "#E5F0FF",
          border: "#e5e5e5",
          dark: "#0F015E",
        },
        special: {
          default: "#E58306",
          alt: "#F1C742",
          dark: "#7D4A00",
          wash: "#FFF5E5",
          border: "#eaeaea",
        },
        text: {
          default: "#252a31",
          secondary: "#969696",
          alt: "#585757",
          placeholder: "#fe9931",
          reverse: "#FFFFFF",
          inkLight: "#5f738c",
        },
        danger: {
          default: "#B42318",
          alt: "#F04438",
          dark: "#85000C",
          wash: "#FFFBFA",
          border: "#FDA29B",
          orange: "#F05A2A",
        },
        warn: {
          default: "#B54708",
          alt: "#F5A623",
          dark: "#A66B00",
          wash: "#FFFCF5",
          border: "#FEC84B",
        },
      },
      zIndex: {
        BACKGROUND: -1,
        DEFAULT: 1,
        TOOLTIP: 1000,
        DROPDOWN: 2000,
        OVERLAY: 3000,
        MODAL: 5000,
        LOADING: 6000,
        TOP: 9999,
      },
      spacing: spacings,
      boxShadow: boxShadows,
    },
  },
  safelist: [
    {
      pattern: /(p|m|gap|shadow)-(none|4xs|3xs|2xs|xs|sm|md|lg|xl|2xl|3xl)/,
    },
  ],
  plugins: [],
};
