"use client";
// eslint-disable-next-line no-restricted-imports
import { IntlProvider, type MessageFormatElement, useIntl } from "react-intl";
import { NextIntlClientProvider } from "next-intl";

import {
  DefaultLanguage,
  getLocaleInfo,
  LocalesMap,
  TLanguage,
  TLocale,
} from "./i18n-settings";

type TI18nProviderProps = {
  children: React.ReactNode;
  lang: string;
  messages: Record<string, MessageFormatElement[]> | Record<string, string>;
};

function I18nProvider({ children, lang, messages }: TI18nProviderProps) {
  const locale = LocalesMap[lang]?.locale;
  return (
    <NextIntlClientProvider locale={lang}>
      <IntlProvider
        messages={messages}
        locale={locale}
        defaultLocale={DefaultLanguage}
      >
        {children}
      </IntlProvider>
    </NextIntlClientProvider>
  );
}

function useLocale(): TLocale {
  const { locale } = useIntl();
  const activeLang = locale.substring(0, 2) as TLanguage;
  const activeLocale = getLocaleInfo(activeLang);
  return activeLocale;
}

export { I18nProvider, useLocale };

// eslint-disable-next-line no-restricted-imports
export {
  FormattedMessage,
  useIntl,
  FormattedNumber,
  FormattedDate,
  type IntlShape,
} from "react-intl";
