import { theme } from "../theme";

export type FontWeight = Pick<React.CSSProperties, "fontWeight">;
export type FontSize = Pick<React.CSSProperties, "fontSize">;
export type Justify = Pick<React.CSSProperties, "justifyContent">;
export type Align = Pick<React.CSSProperties, "alignItems">;
export type Grow = Pick<React.CSSProperties, "flexGrow">;
export type Margin = Pick<React.CSSProperties, "margin">;
export type Flex = Pick<React.CSSProperties, "flex">;
export type FlexWrap = Pick<React.CSSProperties, "flexWrap">;
export type FlexBasis = Pick<React.CSSProperties, "flexBasis">;
export type PaddingSize = keyof typeof Sizes;
export type Color = { color?: keyof typeof theme.colors };
export enum SpacingValues {
  none = "0",
  xxs = "2px",
  xs = "4px",
  s = "8px",
  m = "12px",
  l = "16px",
  xl = "20px",
  xxl = "26px",
  xxxl = "32px",
  xxxxl = "40px",
}
export type Spacing = { spacing?: keyof typeof SpacingValues };
export type SpaceAfter = { spaceAfter?: keyof typeof SpacingValues };
export enum Sizes {
  none = "0px",
  small = "10px",
  medium = "20px",
  large = "30px",
  xlarge = "45px",
}

export type Spacings =
  | "none"
  | "3xs"
  | "2xs"
  | "xs"
  | "sm"
  | "md"
  | "lg"
  | "xl"
  | "2xl"
  | "3xl";

export type BoxShadows = Exclude<Spacings, "3xs" | "2xs">;

export enum ZIndex {
  BACKGROUND = -1,
  DEFAULT = 1,
  TOOLTIP = 1000,
  DROPDOWN = 2000,
  OVERLAY = 3000,
  MODAL = 5000,
  LOADING = 6000,
  TOP = 9999,
}

export enum Breakpoints {
  MOBILE = 480,
  TABLET = 768,
  SMALLSCREEN = 905,
  DESKTOP = 1024,
  WIDESCREEN = 1224,
}

export enum LogoSizes {
  SMALL = 60,
  MEDIUM = 90,
  LARGE = 120,
}
