export type TLanguage = "en" | "ar" | "fr" | "tr" | "de";
export type TDirection = "rtl" | "ltr";

export type TLocale = {
  dir: TDirection;
  code: TLanguage;
  name: string;
  locale: string;
  display: string;
};

export const DefaultLocale: TLocale = {
  dir: "rtl",
  code: "ar",
  locale: "ar-EG",
  name: "arabic",
  display: "العربية",
};

export const OtherLocales: TLocale[] = [
  {
    dir: "ltr",
    code: "en",
    locale: "en-US",
    name: "english",
    display: "English",
  },
  {
    dir: "ltr",
    code: "fr",
    locale: "fr-FR",
    name: "french",
    display: "Francais",
  },
  {
    dir: "ltr",
    code: "tr",
    locale: "tr-TR",
    name: "turkish",
    display: "Türkçe",
  },
  {
    dir: "ltr",
    code: "de",
    locale: "de-DE",
    name: "german",
    display: "Deutsch",
  },
];

export const SupportedLocales: TLocale[] = [DefaultLocale, ...OtherLocales];

export const DefaultLanguage: TLanguage = DefaultLocale.code;

export const OtherLanguages: TLanguage[] = OtherLocales.map(
  (locale) => locale.code
);

export const SupportedLanguages: TLanguage[] = [
  DefaultLanguage,
  ...OtherLanguages,
];

export const LocalesMap = SupportedLocales.reduce<Record<string, TLocale>>(
  (acc, curr) => ({
    ...acc,
    [curr.code]: curr,
  }),
  {}
);

export function getLocaleInfo(language: TLanguage | string) {
  return LocalesMap[language] || DefaultLocale;
}
